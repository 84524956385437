import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { blogPosts } from "../../blogPosts";
// Components
import BlogBox from "../Elements/BlogBox";

export default function Blog() {
  const navigate = useNavigate();
  return (
    <Wrapper id="blog">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Latest News and Specials</h1>
            <p className="font18">
              Get to know our company's latest news, tips from our company among
              others.
            </p>
          </HeaderInfo>
          <div className="row textCenter">
            {blogPosts.map((blog) => {
              return (
                <div
                  className="col-xs-12 col-sm-4 col-md-4 col-lg-4"
                  key={blog.id}
                >
                  <BlogBox
                    title={blog.title}
                    text={blog.desc}
                    tag="Read more"
                    image={blog.image}
                    author="VAMTECH"
                    action={() => navigate(`/blog/${blog.id}`)}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
  margin-top: 150px;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
