import React from "react";
import styled from "styled-components";
import AddImage2 from "../../assets/img/nexa/elect3.jpg";
import ObjectivesText from "./About/ObjectivesText";

const Objectives = () => {
  return (
    <div className="lightBg">
      <div className="container">
        <Advertising className="flexSpaceCenter">
          <AddLeft>
            <AddLeftInner>
              <ImgWrapper className="flexCenter">
                <img className="radius8" src={AddImage2} alt="add" />
              </ImgWrapper>
            </AddLeftInner>
          </AddLeft>
          <AddRight>
            <h4 className="font18 semiBold">
              VAMTECH TECHNICAL SERVICES LIMITED
            </h4>
            <h2 className="font40 extraBold">Our Objectives</h2>
            <p className="font18" style={{ margin: "1.5rem 0" }}>
              We operate with an unwavering commitment honesty and integrity.
              It’s one of our core values. That’s how we were raised; that’s how
              we do business, and that’s how we conduct ourselves personally.
            </p>
            <ObjectivesText />
          </AddRight>
        </Advertising>
      </div>
    </div>
  );
};

const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -150px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;

export default Objectives;
