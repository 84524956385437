import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Assets
import LogoImg from "../../assets/svg/Logo";

export default function Contact() {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <Wrapper>
      <div className="darkBg">
        <div className="container">
          <InnerWrapper
            className="flexSpaceCenter"
            style={{ padding: "30px 0" }}
          >
            <WordWrapper>
              <WordDiv>
                <h1 className="font15 extraBold whiteColor">ABOUT US</h1>
                <h4 className="font15 semiBold whiteColor">
                  Vamtech technical services Ltd set it's foot as a supply and
                  equipment fixing company with creativity. Over the years the
                  company has earned a reputation for excellence and
                  professionalism for continuously striving to meet and even
                  exceed standards which set the climate for a sustainable
                  business relationship with it’s clients in the technical
                  industry.
                </h4>
              </WordDiv>

              <div>
                <h1 className="font15 extraBold whiteColor">USEFUL LINKS</h1>
                <ListWrapper>
                  <a
                    href="/"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    <li>Home</li>
                  </a>
                  <a
                    href="/about"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    <li>About</li>
                  </a>
                  <a
                    href="/contact"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    <li>Contact</li>
                  </a>
                  <a
                    href="/services"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    <li>Services</li>
                  </a>
                </ListWrapper>
              </div>

              <div>
                <h1 className="font15 extraBold whiteColor">COMPANY INFO</h1>
                <h4 className="font15 semiBold whiteColor">
                  VAMTECH TECHNICAL SERVICES LTD
                  <br />
                  Email: info@vamtechltd.com <br />
                  P.O.BOX 130837, Kampala <br />
                  Tel: +256704787967 || +256784693821 || +256750539014
                  <br />
                  Twitter/X: https://x.com/vamtechltd <br />
                  Instagram: https://instagram.com/vamtechltd <br />
                  Facebook: https://facebook.com/vamtech <br />
                  TIkTok: https://TikTok.com/vamtechltd
                </h4>
              </div>
            </WordWrapper>
          </InnerWrapper>
          <InnerWrapper
            className="flexSpaceCenter"
            style={{ padding: "30px 0" }}
          >
            <Link
              className="flexCenter animate pointer"
              to="home"
              smooth={true}
              offset={-80}
            >
              <LogoImg />
              <h1
                className="font15 extraBold whiteColor"
                style={{ marginLeft: "15px" }}
              >
                VAMTECH TECHNICAL SERVICES LTD
              </h1>
            </Link>
            <StyleP className="whiteColor font13">
              © {getCurrentYear()} -{" "}
              <span className="purpleColor font13">
                VAMTECH TECHNICAL SERVICES LTD
              </span>{" "}
              All Right Reserved
            </StyleP>

            <a
              className="whiteColor animate pointer font13"
              href="mailto:rogerrisha@gmail.com"
              smooth="true"
              offset={-80}
            >
              Powered by RCR Tech
            </a>
          </InnerWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;

const WordWrapper = styled.div`
  display: flex;
  gap: 100px;
  @media (max-width: 550px) {
    display: flex;
    gap: 50px;
    flex-direction: column;
  }
`;

const WordDiv = styled.div`
  flex: 1;
  @media (max-width: 550px) {
    width: 100%;
  }
`;

const ListWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  color: white;
  list-style-type: none;
`;
const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const StyleP = styled.p`
  @media (max-width: 550px) {
    margin: 20px 0;
  }
`;
