import React from "react";
import styled from "styled-components";
// Components
import ProjectBox from "../Elements/ProjectBox";
// Assets
import ProjectImg1 from "../../assets/img/nexa/elect1.jpg";
import ProjectImg2 from "../../assets/img/nexa/commercial.jpeg";
import ProjectImg3 from "../../assets/img/nexa/solarpanel.jpg";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

export default function Projects() {
  const WhatWeDo = [
    {
      id: 1,
      image: ProjectImg1,
      title: "Domestic Electrical",
      text: `Vamtech is atrusted supplier of commercial items in and around the world including, electrical equipment, CCTV cameras, solar systems, Generators, Plumbing equipment, Air conditioners, stabilizers and installations .`,
    },
    {
      id: 2,
      image: ProjectImg2,
      title: "Commercial Electrical",
      text: `Vamtech is a trusted supplier of Commercial items in and around
       the world and offer a range of services including Lighting, Electrical Testing such a
        PAT and PART P, and services for Landlords.`,
    },
    {
      id: 3,
      image: ProjectImg3,
      title: "Solar Services",
      text: `We offer a complete end-to-end Solar PV Systems that takes you from an
       initial visit to understand your requirement and survey the property through to installation,repair
        and servicing.`,
    },
  ];

  return (
    <Wrapper id="projects">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">What we do</h1>
            <p className="font15">
              Below are our services that we offer affordably that any one is in
              position to
              <br />
              acquire them.
            </p>
          </HeaderInfo>
          <div className="row textCenter">
            {WhatWeDo.map((service) => {
              return (
                <div
                  className="col-xs-12 col-sm-4 col-md-4 col-lg-4"
                  key={service.id}
                >
                  <ProjectBox
                    img={service.image}
                    title={service.title}
                    text={service.text}
                    action={() => alert("clicked")}
                  />
                </div>
              );
            })}
          </div>

          <Link to="/services">
            <Button
              sx={{ margin: "2rem 0" }}
              variant="contained"
              color="secondary"
            >
              View more Services
            </Button>
          </Link>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  margin-bottom: 10rem;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
