import { Email, Instagram, Twitter, WhatsApp } from "@mui/icons-material";
import Facebook from "@mui/icons-material/Facebook";
import React from "react";
import styled from "styled-components";

const ScrollToTop = () => {
  return (
    <>
      <Wrapper>
        <ul>
          <li>
            <a href="https://facebook.com/vamtech">
              <Facebook sx={{ color: "blue" }} className="icon-social-page" />
            </a>
          </li>
          <li>
            <a href="https://wa.me/+256704693821">
              <WhatsApp sx={{ color: "green" }} className="icon-social-page" />
            </a>
          </li>
          <li>
            <a href="https://x.com/vamtechltd">
              <Twitter sx={{ color: "blue" }} className="icon-social-page" />
            </a>
          </li>
          <li>
            <a href="mailto:info@vamtechltd.com">
              <Email className="icon-social-page" />
            </a>
          </li>
          <li>
            <a href="https://Instagram.com/vamtechltd">
              <Instagram
                sx={{ color: "purple" }}
                className="icon-social-page"
              />
            </a>
          </li>
        </ul>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  top: 150px;
  left: 25px;
  padding: 2px;
  position: fixed;
  background-color: white;
  opacity: 80%;
  & .icon-social-page {
    font-size: 40px;
    cursor: pointer;
  }
  @media (max-width: 600px) {
    left: 5px;
    background-color: white;
    opacity: 90%;
    & .icon-social-page {
      font-size: 30px;
    }
  }
`;
export default ScrollToTop;
