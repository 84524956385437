import React from "react";
import styled from "styled-components";
// Components
import ProjectBox from "../Elements/ProjectBox";
// Assets
import ProjectImg1 from "../../assets/img/nexa/elect1.jpg";
import ProjectImg2 from "../../assets/img/nexa/commercial.jpeg";
import ProjectImg3 from "../../assets/img/nexa/solarpanel.jpg";
import ProjectImg4 from "../../assets/img/another/image (61).jpg";
import ProjectImg6 from "../../assets/img/another/image (164).jpg";
import ProjectImg7 from "../../assets/img/another/ac (1).jpg";
import ProjectImg8 from "../../assets/img/another/image (279).jpg";
import ProjectImg9 from "../../assets/img/nexa/fence.jpg";
import ProjectImg10 from "../../assets/img/nexa/fridge.jpg";
import ProjectImg11 from "../../assets/img/nexa/washing.jpg";
import ProjectImg12 from "../../assets/img/nexa/fire.jpg";
import ProjectImg13 from "../../assets/img/another/image (165).jpg";
import ProjectImg14 from "../../assets/img/nexa/ups.jpg";
import ProjectImg15 from "../../assets/img/another/image (8).jpg";
import ProjectImg16 from "../../assets/img/another/welding.jpg";
import ProjectImg17 from "../../assets/img/another/motor.jpg";
import ProjectImg18 from "../../assets/img/another/plumbing.jpg";
import ProjectImg19 from "../../assets/img/another/power.jpg";
import ProjectImg20 from "../../assets/img/another/fan.jpg";
import ProjectImg21 from "../../assets/img/another/general.jpeg";

export default function OurServices() {
  const WhatWeDo = [
    {
      id: 1,
      image: ProjectImg1,
      title: "Domestic Electrical",
      text: `Vamtech is atrusted supplier of commercial items in and around the world including, electrical equipment, CCTV cameras, solar systems, Generators, Plumbing equipment, Air conditioners, stabilizers and installations .`,
    },
    {
      id: 2,
      image: ProjectImg2,
      title: "Commercial Electrical",
      text: `Vamtech is a trusted supplier of Commercial Electrical Services in and around
       the York area and offer a range of services including Lighting, Electrical Testing such a
        PAT and PART P, and services for Landlords.`,
    },
    {
      id: 3,
      image: ProjectImg3,
      title: "Solar Services",
      text: `We offer a complete end-to-end Solar PV Systems that takes you from an
       initial visit to understand your requirement and survey the property through to installation,repair
        and servicing.`,
    },
    {
      id: 4,
      image: ProjectImg4,
      title: "Testing and commissioning",
      text: `We verify, documennt, and place into services newly installed or retrofitted electrical power
        equipment and systems.`,
    },
    {
      id: 5,
      image: ProjectImg2,
      title: "Preventive maintenance",
      text: `We do regular and routine maintenance of equipment and assets in order to keep them running
         and prevent any costly unplanned downtime from unexpected equipment failure.`,
    },
    {
      id: 6,
      image: ProjectImg6,
      title: "Power system analysis",
      text: ` We do calculations and simulations to verify that the electrical system, including
         the system components, are correctly specified to perform as intended, withstand expected stress
          and be protected against failures.`,
    },
    {
      id: 7,
      image: ProjectImg7,
      title: "Air conditioning services (AC’s)",
      text: ` We do instllation of new Air conditioners to all different buildings, 
        we also do repair and maintainance of existiing air conditioners in buildings.`,
    },
    {
      id: 8,
      image: ProjectImg8,
      title: "CCTV cameras installations and maintaining",
      text: ` We do instllation and maintaining of new and CCTV cameras
         to all different buildings.`,
    },
    {
      id: 9,
      image: ProjectImg9,
      title: "Electric fence intercom",
      text: ` We do instllation and maintaining electric fenses and this adds perimeter 
        protection around different properties.`,
    },
    {
      id: 10,
      image: ProjectImg10,
      title: "Refrigeration maintenance",
      text: ` We do  maintaining of different refrigerators and we provide new refrigerators of
        different sizes.`,
    },
    {
      id: 11,
      image: ProjectImg11,
      title: "Washing machines installations and maintainance",
      text: ` We do installing of new washing machines and  maintaining of the old ones.`,
    },
    {
      id: 12,
      image: ProjectImg12,
      title: "Fire alarm bells and smoke detectors",
      text: `We do installing of emergency fire alarm bells and smoke detectors to save guard against 
        fire outbreaks.`,
    },
    {
      id: 13,
      image: ProjectImg13,
      title: " Voltage regulators and stabilizers",
      text: `We do installation and maintaining of voltage regulators and stabilizers 
        to deliver constant output without in changes in incoming voltage and changes in load current.`,
    },
    {
      id: 14,
      image: ProjectImg14,
      title: "UPS systems,Automation and General electrical supplies",
      text: `We do installing of uninterrupted power supply(UPS) systems that allow computers forexample
        to keep running for at least a short time when incoming power is interrupted,
        we also do general electrical supplies all over world.`,
    },
    {
      id: 15,
      image: ProjectImg15,
      title: "Generator installations and maintaining",
      text: ` We do instllation and maintaining of new and used generators and CCTV cameras
         to all different buildings.`,
    },
    {
      id: 16,
      image: ProjectImg16,
      title: "Welding and fabrication services.",
      text: `We take pride in offering top-tier welding 
      and fabrication services that excel in precision, quality, and craftsmanship.
      This  encompass a wide range of applications, from custom-designed architectural 
      metalwork to heavy-duty industrial components. We utilize cutting-edge 
      welding techniques and state-of-the-art equipment to ensure 
      every project we undertake is executed with the utmost precision and attention to detail.`,
    },

    {
      id: 17,
      image: ProjectImg17,
      title: "Motor installations and rewinding.",
      text: `We specialize in motor installations and rewinding 
      services that keep your machinery running smoothly and efficiently. 
      Our expert technicians have a wealth of experience in handling motors of all sizes and types.
      Whether you need a new motor installed or an existing one rewound to restore its performance, we've got you covered`,
    },
    {
      id: 18,
      image: ProjectImg18,
      title: "Plumbing  and fixing services.",
      text: `We are your trusted partner for all your plumbing and fixing needs. 
      With years of expertise, our skilled plumbers offer reliable solutions for 
      both residential and commercial properties`,
    },
    {
      id: 19,
      image: ProjectImg19,
      title: "Powerlines and transformer installations",
      text: `We are your premier choice for powerline and transformer installations. With a 
      wealth of experience in the electrical industry, our skilled technicians 
      specialize in designing, installing, and maintaining power distribution systems 
      that ensure reliable electricity supply.`,
    },
    {
      id: 20,
      image: ProjectImg20,
      title: "Industrial fans and extractors installation.",
      text: `We specialize in industrial fans and extractors installation, providing efficient
       ventilation solutions for various industrial settings. Our experienced technicians
        are experts in designing, installing, and maintaining industrial-grade fans
         and extractors to ensure optimal air quality and safety.`,
    },
    {
      id: 21,
      image: ProjectImg21,
      title: "Automated change over systems and stablisers",
      text: `We specialize in automated changeover systems and stabilizers, providing advanced
       solutions for uninterrupted power supply and voltage regulation. Our cutting-edge technology
        and expert team ensure that your electrical systems remain stable and reliable,
         even in the face of fluctuations and outages.`,
    },
    {
      id: 22,
      image: ProjectImg21,
      title: "General supplies.",
      text: `We are your trusted source for a wide range of general supplies. With a commitment
       to quality and customer satisfaction, we offer a diverse selection of products
        to meet your everyday needs`,
    },
  ];

  return (
    <Wrapper id="projects">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Our Services</h1>
            <p className="font15">
              All services include , Diagnostics, inspection, installation and
              maintenance services. We also offer free consultation and home
              visits for all services.
            </p>
          </HeaderInfo>
          <div className="row textCenter">
            {WhatWeDo.map((service) => {
              return (
                <div
                  className="col-xs-12 col-sm-4 col-md-4 col-lg-4"
                  key={service.id}
                >
                  <ProjectBox
                    img={service.image}
                    title={service.title}
                    text={service.text}
                    action={() => alert("clicked")}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  margin-top: 10rem;
  margin-bottom: 10rem;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
