import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
// Components
import TestimonialBox from "../Elements/TestimonialBox";

export default function TestimonialSlider() {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <Slider {...settings}>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="As a dependable and reliable in the Technical services sector, As a pro active company capable of competing in all facets of the market place through constant improvement and innovation as a business that satisfies customers with easy and hustle free transactions."
            author="Our Customers."
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="As a creative , experienced and top supplier of Technical products in the industry, As a company who appreciates and recognizes that sustainable partnerships are essential to the company’s success."
            author="Our suppliers"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="As an employer who values the dedication and commitment of it’s employees putting safety first, As an innovative and technologyically upfront company that ensures employee competence and advancement."
            author="Our Employees"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="As a partner that promotes collaboration by engaging in trade associations, As a business that makes a difference to the quality of life through the impact of excellent products , services and solutions as we value clean energy."
            author="Our Industry"
          />
        </LogoWrapper>
      </Slider>
    </div>
  );
}

const LogoWrapper = styled.div`
  width: 90%;
  padding: 0 5%;
  cursor: pointer;
  :focus-visible {
    outline: none;
    border: 0px;
  }
`;

// NEXA Electrical Contractors Ltd, professionals were prompt and efficient in completing the job. They resolved a persistent and potentially hazardous situation by relocating and replacing my very old and obsolete electrical panel, and added a whole house surge protection too. We highly recommend them.
// NEXA Electrical Contractors Ltd have undertaken a lot of work on our property including replacing light fittings, switches and sockets, fitting a new electric shower, and completing earthing works. We are extremely pleased with the work and the service has been professional throughout. We highly recommend.
// NEXA Electrical Contractors Ltd, What a great team! They were able to fix what we needed fast! Amazing electrician with a job well done! we are extremly pleased with the work they did and the professionals they have who get the job done in limitd time. I would highly recommend them!. Looking forward to work with them.
