import React from "react";
// Sections
import Animation from "../components/Animate/Animate";
import Gallery from "../components/Gallery/gallery";

export default function GalleryPage() {
  React.useEffect(() => {
    document.title = "  Gallery Vamtech Technical Services Limited";
  }, []);
  return (
    <>
      <Animation>
        <Gallery />
      </Animation>
    </>
  );
}
