import React from "react";
import image from "../../../assets/img/another/image (216).jpg";
// import FacebookIcon from "@mui/icons-material/Facebook";
// import TwitterIcon from "@mui/icons-material/Twitter";
// import MailIcon from "@mui/icons-material/Mail";

import "./another.css";
import AboutSection from "./AboutSection";
// import { Instagram } from "@mui/icons-material";

const AnotherSection = () => {
  return (
    <div className="section-another">
      <div className="container-another">
        <div className="content-section-another">
          <div>
            <AboutSection />
          </div>
          {/* <div className="social-another">
                <a href="https://www.facebook.com/nexaelectricalcontractorsltd">
                    <FacebookIcon sx={{color:'blue',fontSize:'40px'}}/></a>
                <a href="https://www.twitter.com/NexaContractors">
                    <TwitterIcon sx={{color:'blue',fontSize:'40px'}}/></a>
                <a href="https://www.instagram.com/nexaelectricalcontractorsltd">
                    <Instagram sx={{color:'purple',fontSize:'40px'}}/></a>
                <a href="mailto:example@gmail.com">
                    <MailIcon sx={{color:'black',fontSize:'40px'}}/></a>
            </div> */}
        </div>
        <div className="image-section-another">
          <img src={image} alt="Tio" style={{ borderRadius: "10px" }} />
        </div>
      </div>
    </div>
  );
};

export default AnotherSection;
