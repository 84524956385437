import React from "react";
import { motion } from "framer-motion";
import AnimatedText from "./AnimatedText";
import "./styles.css";
import { Typography } from "@mui/material";

export default function AboutSection() {
  const replay = true;
  // Placeholder text data, as if from API
  const placeholderText = [
    { type: "heading1", text: "Company profile" },
    {
      type: "heading2",
      text: `Vamtech technical services Ltd set it's foot as  a supply and equipment fixing company  with creativity. Over the years the company has earned a reputation for excellence and professionalism for continuously striving to meet and even exceed standards which set the climate for a sustainable business relationship with it’s clients in the technical industry.`,
    },
    {
      type: "heading2",
      text: `Our team is composed of technically skilled and highly dedicated personel working cohesively to promote value added solutions with a competitive pricing structure to suit your needs.`,
    },
    {
      type: "heading2",
      text: `The company partners with some of the biggest manufacturers in the technical industry to ensure high quality products . We look forward to providing our services and products to you always, rest assured that we live by our service commitment , delivering results exceeding expectations thats why at Vamtech technical services ltd we belive in Innovation with creativity.`,
    },
  ];

  const container = {
    visible: {
      transition: {
        staggerChildren: 0.025,
      },
    },
  };

  return (
    <motion.div
      initial="hidden"
      // animate="visible"
      animate={replay ? "visible" : "hidden"}
      variants={container}
      className="container-word"
    >
      {/* <div className="container-word"> */}
      <Typography variant="div">
        {placeholderText.map((item, index) => {
          return <AnimatedText {...item} key={index} />;
        })}
        {/* </div> */}
      </Typography>
    </motion.div>
  );
}
