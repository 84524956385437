import React from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// Screens
import Landing from "./screens/Landing.jsx";
import { motion, useScroll } from "framer-motion";
import TopNavbar from "./components/Nav/TopNavbar.jsx";
import Footer from "./components/Sections/Footer";
import ErrorElement from "./screens/errorElement.jsx";
import AboutUs from "./screens/About";
import HireUs from "./screens/Hire.jsx";
import Contact from "./screens/Contact";
import Gallery from "./screens/Gallery";
import OurServices from "./screens/OurServices";
import BlogPage from "./screens/Blog.jsx";
import BlogPageSingle from "./components/SingleBlog/BlogPageSingle.jsx";
import ScrollToTop from "./components/Scroll/scroll.jsx";
import ContactSocial from "./components/Nav/ContactSocial.jsx";

export default function App() {
  const { scrollYProgress } = useScroll();
  return (
    <>
      <motion.div
        className="progress-bar"
        style={{ scaleX: scrollYProgress }}
      />
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Router>
        <ContactSocial />
        <TopNavbar />
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/hire" element={<HireUs />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/services" element={<OurServices />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/blog/:id" element={<BlogPageSingle />} />
          <Route path="*" element={<ErrorElement />} />
        </Routes>
        <Footer />
        <ScrollToTop />
      </Router>
    </>
  );
}
