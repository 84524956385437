import React from "react";
// Sections
import Animation from "../components/Animate/Animate";
import Testimonial from "../components/Sections/Testmonial";
import VisionMission from "../components/About/visionmission";
import AnotherSection from "../components/About/About/another";
import OurTeam from "../components/About/ourTeam";
import { motion } from "framer-motion";
import Objectives from "../components/About/objectives";
import Recognized from "../components/About/recognized";

export default function AboutUs() {
  React.useEffect(() => {
    document.title = "About Vamtech Technical Services Limited";
  }, []);

  return (
    <>
      <div className="hero-about">
        <div className="hero-text">
          <motion.h1
            initial={{ x: 100 }}
            animate={{ scale: 1, rotate: 5, color: "goldenrod" }}
            transition={{ delay: 1 }}
            className="goldcolor"
          >
            About us
          </motion.h1>
        </div>
      </div>
      <AnotherSection />
      <Animation>
        <VisionMission />
      </Animation>
      <Animation>
        <Objectives />
      </Animation>
      <Animation>
        <Recognized />
      </Animation>
      <Animation>
        <OurTeam />
      </Animation>
      <Animation>
        <Testimonial />
      </Animation>
    </>
  );
}
