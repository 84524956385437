import React from "react";
import styled from "styled-components";
// Components
// import ClientSlider from "../Elements/ClientSlider";
import ServiceBox from "../Elements/ServiceBox";

// Assets

export default function WhyChooseUs() {
  return (
    <Wrapper id="services">
      {/* <div className="lightBg" style={{ padding: "50px 0" }}>
        <div className="container">
          <ClientSlider />
        </div>
      </div> */}
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">
              <b style={{ color: "gold" }}>Why </b> Choose Us
            </h1>
            <p className="font18">
              We operate with an unwavering commitment honesty and integrity.
              It’s one of our core values at Vamtech
              <br />
              hence ensuring professionalism with a competitive pricing
              structure to suit our clients.
            </p>
          </HeaderInfo>
          <ServiceBoxRow className="flex">
            <ServiceBoxWrapper>
              <ServiceBox
                icon="roller"
                title="Professional  touch."
                subtitle="VAMTECH TECHNICAL SERVICES LIMITED has a number of experienced Employees well trained and skilled to cater for all our clients needs."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="monitor"
                title="24 x 7 Customer Support"
                subtitle="Our support staff is available 24hrs a day, contact us for any inquiries about any service that we can help you with."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="browser"
                title="Better pricing"
                subtitle="Our services are highly affordable with a competitive pricing structure to suit our clients needs."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="printer"
                title="Each Project is welcome"
                subtitle="With our team, you will be provided with one -on- one attention and kept upto date through out the course of your project as we strive to ensure that each customer is pleased with our services at Vamtech"
              />
            </ServiceBoxWrapper>
          </ServiceBoxRow>

          <ServiceBoxRow className="flex">
            <ServiceBoxWrapper>
              <ServiceBox
                icon="printer"
                title="We care like ours"
                subtitle="Choose Vamtech because we care like it's our own. Our unwavering commitment to your satisfaction sets us apart in every aspect of our service."
              />
            </ServiceBoxWrapper>

            <ServiceBoxWrapper>
              <ServiceBox
                icon="printer"
                title="Fast delivery"
                subtitle="Lightning-fast delivery that puts your needs first. We understand that time is of the essence, and we're committed to getting your products or services to you promptly."
              />
            </ServiceBoxWrapper>

            <ServiceBoxWrapper>
              <ServiceBox
                icon="printer"
                title="Satisfaction guaranteed."
                subtitle="Choose Vamtech with confidence, because your satisfaction is our unwavering commitment. We go the extra mile to ensure that every interaction with us exceeds your expectations."
              />
            </ServiceBoxWrapper>
          </ServiceBoxRow>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 80px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
