import { Typography } from "@mui/material";
import mainImageBlog1 from "./assets/img/another/image (232).jpg";

export const blogPosts = [
  {
    id: 1,
    title: "Tips for Home Electrical Safety",
    desc: "Get to know some home tips on how to protect your family against some harzards caused by electricity",
    image: mainImageBlog1,
    otherImages: [],
    moreInfo: (
      <Typography variant="div" component="div">
        <Typography component="h4" variant="h4" sx={{ marginBottom: "30px" }}>
          VAMTECH TECHNICAL SERVICES LIMITED
        </Typography>
        <Typography component="h5" variant="h5" sx={{ marginBottom: "20px" }}>
          Extension Cords
        </Typography>
        <ul style={{ marginBottom: "20px" }} className="font18">
          <li>
            Since frayed or cracked cords can cause shock or fire, replaced old
            or damaged extension cords.
          </li>
          <li>Never run an extension cord under a rug.</li>
          <li>Never overload an extension cord – it could cause a fire.</li>
          <li>Only use extension cord in temporary situations.</li>
        </ul>
        <Typography component="h5" variant="h5" sx={{ marginBottom: "20px" }}>
          Receptacles
        </Typography>
        <ul style={{ marginBottom: "20px" }} className="font18">
          <li>
            Replace any receptacles that are broken, feel hot to the touch,
            spark or make a noise when inserting a plug, or can no longer hold a
            plug securely.
          </li>
          <li>
            Never alter polarized plugs to make them fit into an unpolarized
            receptacle.
          </li>
          <li>
            Do not use multi-plug adapters for extended periods of time.
            Instead, add a new receptacle and circuit if necessary.
          </li>
        </ul>
        <Typography component="h5" variant="h5" sx={{ marginBottom: "20px" }}>
          Kitchen Appliances
        </Typography>
        <ul style={{ marginBottom: "20px" }} className="font18">
          <li>
            Unplug kitchen appliances when not in use. Unattended, plugged-in
            appliances can create an unnecessary risk of fire.
          </li>
          <li>
            Make sure that all appliances are away from the sink area. Mixing
            electricity and water can result in an electric shock or fire
            hazard.
          </li>
          <li>Remove all combustibles from the cooking range.</li>
        </ul>
        <Typography component="h5" variant="h5" sx={{ marginBottom: "20px" }}>
          Electric Blankets
        </Typography>
        <ul style={{ marginBottom: "20px" }} className="font18">
          <li>
            Cracks, breaks in wiring, plugs or connectors are a sign that you
            need to discard the blanket.
          </li>
          <li>
            Do not allow anything on top of an electric blanket (including pets)
            when in use.
          </li>
          <li>Never fold an electric blanket when in use.</li>
          <li>
            Never tuck in an electric blanket. Pinching the wires can cause
            damage, which can create a fire hazard.
          </li>
        </ul>
        <Typography component="h5" variant="h5" sx={{ marginBottom: "20px" }}>
          Circuit Breakers
        </Typography>
        <ul style={{ marginBottom: "20px" }} className="font18">
          <li>
            Turn off the freezer, refrigerator, and air conditioner. Flip each
            circuit breaker on and off three times; do this at least once a
            year.
          </li>
          <li>
            Test Ground Fault Circuit Interrupters (GFCIs) monthly. Test by
            pushing the test button, and reset by moving the breaker handle to
            the off position and then to the on position. Defective GFCI circuit
            breakers should only be replaced by a qualified electrician.
          </li>
          <li>
            Test Arc Fault Circuit Interrupters (AFCIs) monthly. You do this by
            following the directions for the GFCI test above. If you have a
            defective AFCI circuit breaker, it should be replaced by a qualified
            electrician.
          </li>
        </ul>
        <Typography component="h4" variant="h4" sx={{ marginBottom: "20px" }}>
          For more home safety tips, contact us today!
        </Typography>
      </Typography>
    ),
  },
];
