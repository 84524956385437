import React from 'react'
import { motion } from "framer-motion"

const ErrorElement = () => {
    
  return (
    <div>
        <motion.div
            animate={{
                scale: [1, 2, 2, 1, 1],
                rotate: [0, 0, 180, 180, 0],
                borderRadius: ["0%", "0%", "50%", "50%", "0%"]
              }}
        >
            <div style={{textAlign: 'center',margin: '20rem 0'}}>
              <h1>
                Opps
              </h1>

            </div>
        </motion.div>
        <div>
            <h2>
              looks like you followed a wrong link
            </h2>
            <p>Click <a href="/">here to go back</a></p>
        </div>
    </div>
  )
}

export default ErrorElement