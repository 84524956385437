import React from "react";
import OurServices from "../components/OurServices/OurServices";
import Animation from "../components/Animate/Animate";
import Testimonial from "../components/Home/Testmonial";

const Services = () => {
  React.useEffect(() => {
    document.title = "  Services Vamtech Technical Services Limited";
  }, []);
  return (
    <div>
      <Animation>
        <OurServices />
      </Animation>
      <Animation>
        <Testimonial />
      </Animation>
    </div>
  );
};

export default Services;
