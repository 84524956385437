import React from "react";
// Sections
import Animation from "../components/Animate/Animate";
import Testimonial from "../components/Home/Testmonial";
import WhyChooseUs from "../components/Sections/WhyChooseUs";
import Blog from "../components/Sections/Blog";

export default function BlogPage() {
  React.useEffect(() => {
    document.title = "  Blog Vamtech Technical Services Limited";
  }, []);

  return (
    <>
      <Animation>
        <Blog />
      </Animation>
      <Animation>
        <WhyChooseUs />
      </Animation>
      <Animation>
        <Testimonial />
      </Animation>
    </>
  );
}
