import React from "react";
import styled from "styled-components";
import AddImage2 from "../../assets/img/nexa/elect1.jpg";

const VisionMission = () => {
  return (
    <div className="lightBg">
      <div className="container">
        <Advertising className="flexSpaceCenter">
          <AddLeft>
            <AddLeftInner>
              <ImgWrapper className="flexCenter">
                <img className="radius8" src={AddImage2} alt="add" />
              </ImgWrapper>
            </AddLeftInner>
          </AddLeft>
          <AddRight>
            <h4 className="font18 semiBold">
              VAMTECH TECHNICAL SERVICES LIMITED
            </h4>
            <h2 className="font40 extraBold" style={{ marginTop: "20px" }}>
              Our Vision And Mission
            </h2>
            <p className="font18" style={{ marginTop: "20px" }}>
              We operate with an unwavering commitment honesty and integrity.
              It’s one of our core values at Vamtech hence ensuring
              professionalism with a competitive pricing structure to suit our
              clients.
            </p>
            <p className="font20" style={{ marginTop: "20px" }}>
              <b>Mission: </b>
              Vamtech Technical Services Limited is committed to providing the
              highest quality products and services exceeding our clients
              expectations . We shall continue to expand in equipment and
              facilities and engage in programs to further serve customers with
              utmost excellence and professionalism as our commitment to service
              is to delivering results , exceeding expectations as we value
              clean energy.
            </p>
            <p className="font20" style={{ marginTop: "20px" }}>
              <b>Vision: </b>
              Vamtech Technical Services Limited firmly believes that Technical
              Services is the cornerstone of a high quality life so there will
              be a never ending need for products and services to harness and
              maximise it’s full potential and use . In doing so , We shall be
              the best in quality provider in our products and services.
            </p>
          </AddRight>
        </Advertising>
      </div>
    </div>
  );
};

const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 100%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -200px;
  left: 0;
  @media (max-width: 1190px) {
    top: -150px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: 420px;
    @media (max-width: 600px) {
      height: 300px;
    }
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;

export default VisionMission;
