import React from "react";
import styled from "styled-components";


const ScrollToTop = () => {
  const [scrollTop, setScrollTop] = React.useState(false);
  React.useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 340) {
        setScrollTop(true);
      } else {
        setScrollTop(false);
      }
    });
  }, []);
  const bottomToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      {scrollTop && (
        <Button onClick={bottomToTop} className="backToTop">
          &#8593;
        </Button>
      )}
    </>
  );
};

const Button = styled.button`
    bottom: 100px;
    right: 25px;
    position: fixed;
    font-size: 80px;
    height: 100px;
    color: #ffffff;
    cursor: pointer;
    border: none;
    border-radius: 55px;
    -webkit-border-radius: 55px;
    -moz-border-radius: 55px;
    -ms-border-radius: 55px;
    -o-border-radius: 55px;
    background: rgb(16, 12, 45);
    &:hover {
        background: rgb(21, 28, 229);
    }
`
export default ScrollToTop;