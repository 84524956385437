import React from "react";
import "./ourTeam.css";
// import MailIcon from "@mui/icons-material/Mail";
import image from "../../assets/img/nexa/matovu2.jpg";
import image1 from "../../assets/img/nexa/patrick2.jpg";
import image2 from "../../assets/img/nexa/newp1.jpeg";
import image3 from "../../assets/img/nexa/patricia.jpg";
import image4 from "../../assets/img/nexa/new2.jpeg";
// import { WhatsApp } from "@mui/icons-material";

const OurTeam = () => {
  return (
    <section className="section-our-team">
      <div className="row-our-team">
        <h1>Our Team</h1>
      </div>
      <div className="row-our-team">
        <div className="column-our-team">
          <div className="card-our-team">
            <div className="img-container">
              <img src={image} alt="founder" style={{ objectFit: "cover" }} />
            </div>
            <h3>Eng. Matovu Nasser</h3>
            <p>Director</p>

            {/* <div className="icons">
              <a href="mailto:mnasseriq@gmail.com">
                <MailIcon />
              </a>
              <span>mnasseriq@gmail.com</span> <br />
            </div> */}
            {/* <p>
              <a href="https://wa.me/+256759008479">
                <WhatsApp />
              </a>
            </p>
            <p>Tel: +256759008479 || +256787682185</p> */}
          </div>
        </div>
        <div className="column-our-team">
          <div className="card-our-team">
            <div className="img-container">
              <img src={image1} alt="Manager" style={{ objectFit: "cover" }} />
            </div>
            <h3>Eng. Kasozi Patrick</h3>
            <p>Director</p>
            {/* <div className="icons">
              <a href="mailto:KasoziPatrick30@gmail.com">
                <MailIcon />
              </a>
              <span>KasoziPatrick30@gmail.com</span>
            </div> */}
            {/* <p>
              <a href="https://wa.me/+256704693821">
                <WhatsApp />
              </a>
            </p>
            <p>Tel: +256704693821 || +256784693821</p> */}
          </div>
        </div>
        <div className="column-our-team">
          <div className="card-our-team">
            <div className="img-container">
              <img src={image3} alt="Manager" style={{ objectFit: "cover" }} />
            </div>
            <h3>Eng. Promise Martha</h3>
            <p>Secretary</p>
            {/* <div className="icons">
              <a href="mailto:twebazepromise20@gmail.com">
                <MailIcon />
              </a>
              <span>twebazepromise20@gmail.com</span>
            </div> */}
          </div>
        </div>
        <div className="column-our-team">
          <div className="card-our-team">
            <div className="img-container">
              <img src={image2} alt="Manager" style={{ objectFit: "cover" }} />
            </div>
            <h3>Collines Nuwagaba Bateho</h3>
            <p>Director</p>
            {/* <div className="icons">
              <a href="mailto:ncbcollins@gmail.com">
                <MailIcon />
              </a>
              <span>ncbcollins@gmail.com</span>
            </div> */}
            {/* <p>
              <a href="https://wa.me/+256756912470">
                <WhatsApp />
              </a>
            </p>
            <p>Tel: +256756912470 || +256761417209</p> */}
          </div>
        </div>
        <div className="column-our-team">
          <div className="card-our-team">
            <div className="img-container">
              <img src={image4} alt="Manager" style={{ objectFit: "cover" }} />
            </div>
            <h3>Eng. Vicent Male </h3>
            <p>Director</p>
            {/* <div className="icons">
              <a href="mailto:malevicent96@gmail.com">
                <MailIcon />
              </a>
              <span>malevicent96@gmail.com</span>
            </div> */}
            {/* <p>
              <a href="https://wa.me/+256771037645">
                <WhatsApp />
              </a>
            </p>
            <p>Tel: +256771037645 || +256750539014</p> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurTeam;
