import React from 'react';
import TestimonialSlider from "../Elements/TestimonialSlider";
import styled from "styled-components";

const Testimonial = () => {
    return (
        <div className="lightBg" style={{padding: '50px 0'}}>
            <div className="container">
            <HeaderInfo>
                <h1 className="font40 extraBold">What They Say About us?</h1>
            </HeaderInfo>
            <TestimonialSlider />
            </div>
        </div>
    )
}

const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;

export default Testimonial;