import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { blogPosts } from "../../blogPosts";

export default function BlogPageSingle() {
    const { id } = useParams();
    const currentPost = blogPosts.find((post) => post.id === parseInt(id));
    if(!currentPost){
        return <h1>No post found for id of {id}</h1>
    }
  return (
    <WrapperBtn className="animate pointer">
      <Wrapper className="whiteBg radius8 shadow">
        <h3 className="font20 extraBold">{currentPost.title}</h3>
        <Image src={currentPost.image} alt={currentPost.title} />
        <p className="font13" style={{ padding: "30px 0" }}>
          {currentPost.desc}
        </p>
        <div>
            {
                currentPost.moreInfo
            }
        </div>
      </Wrapper>
    </WrapperBtn>
  );
}

const Wrapper = styled.div`
  width: 100%;
  text-align: left;
  padding: 20px 30px;
  margin-top: 100px;
  margin-bottom: 40px;
`;

const Image = styled.img`
  width: 80%
`
const WrapperBtn = styled.button`
  border: 0px;
`;
