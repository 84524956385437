import React from "react";
// Sections
import Animation from "../components/Animate/Animate";
import Testimonial from "../components/Sections/Testmonial";
import { motion } from "framer-motion";
import Contact from "../components/Contact/contact";

export default function ContactUs() {
  React.useEffect(() => {
    document.title = "ContactVamtech Technical Services Limited";
  }, []);
  return (
    <>
      <div class="hero-image hero-contact">
        <div class="hero-text">
          <motion.h1
            initial={{ x: 100 }}
            animate={{ scale: 1, rotate: 5, color: "goldenrod" }}
            transition={{ delay: 1 }}
            className="goldcolor"
          >
            Contact us
          </motion.h1>
        </div>
      </div>
      <Animation>
        <Contact />
      </Animation>
      <Animation>
        <Testimonial />
      </Animation>
    </>
  );
}
