import React, { useState } from "react";
import styled from "styled-components";
// Assets
import ContactImg1 from "../../assets/img/nexa/elect1.jpg";
import ContactImg2 from "../../assets/img/nexa/solar2.jpg";
import ContactImg3 from "../../assets/img/nexa/elect5.jpg";

import emailjs from "@emailjs/browser";

const initial_state = {
  name: "",
  email: "",
  subject: "",
  message: "",
};

export default function Contact() {
  const [state, setState] = useState(initial_state);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setState({ ...state, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const templateParams = {
      name: state.name,
      email: state.email,
      subject: state.subject,
      message: state.message,
      company: "info@vamtechltd.com",
      category: "CONTACT FORM",
    };
    return emailjs
      .send(
        "service_d7ee551",
        "template_r70hux9",
        templateParams,
        "09HltgGpibF5GTKAJ"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setState({ name: "", email: "", subject: "", message: "" });
          // setModal(true)
          setSuccessMessage(
            `Message has been successfully sent we will get back to you on ${state.email} shortly`
          );
        },
        (err) => {
          console.log("FAILED...", err);
          // setModal(true)
          setErrorMessage(
            `Sorry Message has not been sent this could be because of slow Internet connection.`
          );
        }
      );
  };

  React.useEffect(() => {
    setTimeout(() => {
      setSuccessMessage("");
      setErrorMessage("");
    }, 4000);
    // return () => clearTimeout(time)
  }, [successMessage, errorMessage]);

  return (
    <Wrapper id="contact">
      <div className="lightBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Contact us</h1>
            <p className="font20">
              <b>Find us at:</b>P.O.BOX 130837, Kampala
              <br />
              <b>Email:</b> info@vamtechltd.com
              <br />
              <b>Call us on:</b> +256704787967 || +256784693821 || +256750539014
              <br />
            </p>
          </HeaderInfo>
          <HeaderInfo>
            <h1 className="font40 extraBold">Please fill out the form</h1>
            <p className="font20">
              We will get back to you as soon as we recieve your message.
              <br />
              Fill out all the fields please
            </p>
          </HeaderInfo>
          <div className="row" style={{ paddingBottom: "30px" }}>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
              {successMessage && (
                <p style={{ color: "green" }}>{successMessage}</p>
              )}
              <Form>
                <label className="font13">First name:</label>
                <input
                  type="text"
                  id="fname"
                  name="name"
                  value={state.name}
                  onChange={handleChange}
                  className="font20 extraBold"
                />
                <label className="font13">Email:</label>
                <input
                  type="text"
                  id="email"
                  value={state.email}
                  onChange={handleChange}
                  name="email"
                  className="font20 extraBold"
                />
                <label className="font13">Subject:</label>
                <input
                  type="text"
                  id="subject"
                  value={state.subject}
                  onChange={handleChange}
                  name="subject"
                  className="font20 extraBold"
                />
                <textarea
                  rows="4"
                  value={state.message}
                  onChange={handleChange}
                  cols="50"
                  type="text"
                  id="message"
                  name="message"
                  className="font20 extraBold"
                />
              </Form>
              <SumbitWrapper className="flex">
                <ButtonInput
                  type="submit"
                  onClick={handleSubmit}
                  value="Send Message"
                  className="pointer animate radius8"
                  style={{ maxWidth: "220px" }}
                />
              </SumbitWrapper>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flex">
              <div
                style={{ width: "50%" }}
                className="flexNullCenter flexColumn"
              >
                <ContactImgBox>
                  <img src={ContactImg1} alt="office" className="radius6" />
                </ContactImgBox>
                <ContactImgBox>
                  <img src={ContactImg2} alt="office" className="radius6" />
                </ContactImgBox>
              </div>
              <div style={{ width: "50%" }}>
                <div style={{ marginTop: "100px" }}>
                  <img
                    src={ContactImg3}
                    alt="office"
                    className="radius6"
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.input`
  border: 1px solid #7620ff;
  background-color: #7620ff;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #580cd2;
    border: 1px solid #7620ff;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;
const ContactImgBox = styled.div`
  max-width: 180px;
  align-self: flex-end;
  margin: 10px 30px 10px 0;
  img {
    width: 100%;
  }
`;
const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;
