import React from "react";
// Sections
import Animation from "../components/Animate/Animate";
import Testimonial from "../components/Sections/Testmonial";
import { motion } from "framer-motion";
import Hire from "../components/Hire/hire";

export default function HireUs() {
  React.useEffect(() => {
    document.title = "Hire Vamtech Technical Services Limited";
  }, []);

  return (
    <>
      <div className="hire-image">
        <div className="hero-text">
          <motion.h1
            initial={{ x: 100 }}
            animate={{ scale: 1, rotate: 5, color: "goldenrod" }}
            transition={{ delay: 1 }}
            className="goldcolor"
          >
            Hire us
          </motion.h1>
        </div>
      </div>
      <Animation>
        <Hire />
      </Animation>
      <Animation>
        <Testimonial />
      </Animation>
    </>
  );
}
