import React from "react";
import PhotoAlbum from "react-photo-album";

const  Gallery = () => {
  const importAll = (context) => context.keys().map(context);
  const images = importAll(require.context('../../assets/img/nexa_new1', false, /\.(png|jpe?g|svg)$/));
  const photosx = images.map((image) => {
    return {
      src: image,width: 500,height: 600
    }
  })
  return (
    <div style={{margin:'7rem 1rem 5rem 1rem'}}>
      <PhotoAlbum layout="masonry" photos={photosx} />
    </div>
  );
}


export default Gallery
