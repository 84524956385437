import React from "react";
// Sections
import Header from "../components/Home/Header";
import Projects from "../components/Home/Projects";
import AboutSec from "../components/Home/AboutSec";
import Animation from "../components/Animate/Animate";
import Testimonial from "../components/Home/Testmonial";
import OurWork from "../components/Home/OurWork";
import WhyChooseUs from "../components/Sections/WhyChooseUs";

export default function Landing() {
  React.useEffect(() => {
    document.title = "Home Vamtech technical services ltd";
  }, []);

  return (
    <>
      <Animation>
        <Header />
      </Animation>
      <Animation>
        <Projects />
      </Animation>
      <Animation>
        <AboutSec />
      </Animation>
      <Animation>
        <WhyChooseUs />
      </Animation>
      <Animation>
        <OurWork />
      </Animation>
      <Animation>
        <Testimonial />
      </Animation>
    </>
  );
}
