import React from "react";
import ImageGallery from "react-image-gallery";
import "./ourwork.css";

const OurWork = () => {
  const importAll = (context) => context.keys().map(context);
  const images = importAll(require.context('../../assets/img/nexa_new1', false, /\.(png|jpe?g|svg)$/));
  // slice only 30 images
  const sliced = images.slice(0, 30);
  const photos = sliced.map((image, i) => {
    return {
      original: image,
      originalClass: "featured-slide-image"+i,
      thumbnail: image,
    }
  })
  return (
    <div className="our-work-wrap">
      <div className="dammy-text-ourWork">
        <h1 className="extraBold font60 head-text-our-owesome">
          View our awesome work
        </h1>
      </div>
      <ImageGallery
        items={photos}
        infinite="true"
        additionalClass="app-image-gallery"
        autoPlay="true"
      />
    </div>
  );
};

export default OurWork;
