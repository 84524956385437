import React from "react";
import styled from "styled-components";
import AddImage1 from "../../assets/img/nexa/elect1.jpg";
import AddImage2 from "../../assets/img/nexa/solar.jpg";
import AddImage3 from "../../assets/img/nexa/elect3.jpg";
import AddImage4 from "../../assets/img/nexa/elect5.jpg";
import FullButton from "../Buttons/FullButton";
import { useNavigate } from "react-router-dom";

const AboutSec = () => {
  let navigate = useNavigate();
  return (
    <div className="lightBg" style={{ margin: "2rem 0" }}>
      <div className="container">
        <Advertising className="flexSpaceCenter">
          <AddLeft>
            <h4 className="font15 semiBold">A few words about company</h4>
            <h2 className="font40 extraBold">
              VAMTECH TECHNICAL SERVICES LIMITED
            </h2>
            <p className="font14">
              Vamtech technical services Ltd set it's foot as a supply and
              equipment fixing company with creativity. Over the years the
              company has earned a reputation for excellence and professionalism
              for continuously striving to meet and even exceed standards which
              set the climate for a sustainable business relationship with it’s
              clients in the technical industry.
            </p>
            <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0" }}>
              <div style={{ width: "190px" }}>
                <FullButton
                  title="Read More"
                  action={() => navigate("/about")}
                />
              </div>
              <div style={{ width: "190px", marginLeft: "15px" }}>
                <FullButton
                  title="Contact Us"
                  action={() => navigate("/contact")}
                  border
                />
              </div>
            </ButtonsRow>
          </AddLeft>
          <AddRight>
            <AddRightInner>
              <div className="flexNullCenter">
                <AddImgWrapp1 className="flexCenter">
                  <img src={AddImage1} alt="office" />
                </AddImgWrapp1>
                <AddImgWrapp2>
                  <img src={AddImage2} alt="office" />
                </AddImgWrapp2>
              </div>
              <div className="flexNullCenter">
                <AddImgWrapp3>
                  <img src={AddImage3} alt="office" />
                </AddImgWrapp3>
                <AddImgWrapp4>
                  <img src={AddImage4} alt="office" />
                </AddImgWrapp4>
              </div>
            </AddRightInner>
          </AddRight>
        </Advertising>
      </div>
    </div>
  );
};

const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: -70px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 48%;
  margin: 0 6% 10px 6%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 30%;
  margin: 0 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled.div`
  width: 20%;
  margin-left: 40%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled.div`
  width: 30%;
  margin: 0 5%auto;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;

export default AboutSec;
